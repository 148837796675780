import { useEffect, useState } from 'react'

function Answer(props) {
  const [answer, setAnswer] = useState({});
  const [questionId, setQuestionId] = useState(0);

  useEffect(() => {
    setAnswer(props.answer);
    setQuestionId(props.questionId);
  }, [props.answer, props.questionId])

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    props.handle(+selectedValue);
  };

  return (
    <li>
      <input
        type="radio"
        id={`quiz-${answer.answerId}`}
        name={`quiz-${questionId}`}
        value={answer.answerId}
        className="hidden peer"
        onChange={handleRadioChange}
      />
      <label htmlFor={`quiz-${answer.answerId}`} className="inline-flex items-center justify-between w-full px-4 py-3 text-gray bg-white border-2 border-gray rounded-2xl cursor-pointer peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary">                           
        <div className="block">
          <div className="w-full text-base">{answer.description}</div>
        </div>
      </label>
    </li>
  )
}

export default Answer