/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useQuizData from '../../services/useQuizData';
import IcoPlay from '../../images/svg/ico_play.svg';
import IcoStop from '../../images/svg/ico_stop.svg';
import IcoQR from '../../images/svg/ico_qr.svg';
import IcoDash from '../../images/svg/ico_dashboard.svg';

function GroupActions(props) {
  const [eventGroup, setEventGroup] = useState({});
  const [event, setEvent] = useState(null);
  const [business, setBusiness] = useState(null);
  const { quizData } = useQuizData();

  const reloadData = async () => {
    const quizDataBody = await quizData();
    setEvent(quizDataBody.event);
    setBusiness(quizDataBody.businessName);
  }

  useEffect(() => {
    setEventGroup(props.eventGroup);
    reloadData();
  }, [props])

  const handlePlay = () => {
    props.handlePlay(eventGroup.idEventGroup);
  }

  const handleStop = () => {
    props.handleStop(eventGroup.idEventGroup);
  }

  return (
    <div className="flex gap-2 justify-end">
      {
        eventGroup.status === 0 && (
          <button onClick={handlePlay} className="bg-primary w-8 h-8 rounded-lg flex justify-center items-center">
            <img src={IcoPlay} alt='play' width={16} height={16} />
          </button>
        )
      }
      {
        (eventGroup.status === 1 || eventGroup.status === 2) && (
          <Link to={`/${business}/${event}/${eventGroup.idEventGroup}/${+event === 3 ? 'visor-riesgo' : 'visor'}`} className='bg-black w-8 h-8 rounded-lg flex justify-center items-center' target='_blank'>
            <img src={IcoDash} alt='link' width={16} height={16} />
          </Link>
        )
      }
      {
        eventGroup.status === 1 && (
          <>
            <Link to={`/${business}/${event}/${eventGroup.idEventGroup}/registro`} className='bg-gray w-8 h-8 rounded-lg flex justify-center items-center' target='_blank'>
              <img src={IcoQR} alt='link' width={16} height={16} />
            </Link>
            <button onClick={handleStop} className="bg-secondary w-8 h-8 rounded-lg flex justify-center items-center">
              <img src={IcoStop} alt='stop' width={16} height={16} />
            </button>
          </>
        )
      }
    </div>
  )
}

export default GroupActions