/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useQuizData from '../../services/useQuizData';
import Answer from '../Answer';
import RiskAnswer from '../RiskAnswer';
import './styles.css';

function Question(props) {
  const [idEvent, setIdEvent] = useState(null);
  const [question, setQuestion] = useState({});
  const [answers, setAnswers] = useState([]);
  const { quizData } = useQuizData();
  
  useEffect(() => {
    handleUnMount();
    setQuestion(props.question.question);
    setAnswers(props.question.answers);
  }, [props.question])

  const handleSelectedAnswer = (answer) => {
    props.handle(answer);
  }

  const handleUnMount = async () => {
    const quizDataBody = await quizData();
    setIdEvent(+quizDataBody.event);
  }

  const htmlQuestion = {
    __html: question.description
  }

  return (
    <form className="w-full pt-6 pb-24">
      <div className="question-wrapper mb-6">
        <h1 className='text-lg font-medium text-left text-gray question' dangerouslySetInnerHTML={htmlQuestion}></h1>
      </div>
      {
        idEvent === 3 ? (
          <div>
            <div className="flex items-center justify-center rounded-lg overflow-hidden">
              <div className="relative w-64 h-64 bg-gray-500">
                {
                  answers.map((answer, idx) => (
                    <RiskAnswer
                      key={answer.answerId}
                      questionId={question.questionId}
                      answer={answer}
                      handle={handleSelectedAnswer}
                      position={idx}
                    />
                  ))
                }
                <div className="absolute arrow arrow-y bg-gray/10 top-2/4 left-2/4"></div>
                <div className="absolute arrow arrow-x bg-gray/10 top-2/4 left-2/4"></div>
              </div>
            </div>
          </div>
        )
        : (
          <ul className="answer-wrapper grid grid-cols-1 gap-5 text-gray font-medium">
            {
              answers.map((answer) => (
                <Answer
                  key={answer.answerId}
                  questionId={question.questionId}
                  answer={answer}
                  handle={handleSelectedAnswer}
                />
              ))
            }
          </ul>
        )
      }
    </form>
  )
}

export default Question