import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import DashboardRisk from './pages/DashboardRisk';
import Register from './pages/Register';
import Quiz from './pages/Quiz';
import GroupManagement from './pages/GroupManagement';
import QuizRedirect from './components/QuizRedirect';
import DashboardRedirect from './components/DashboardRedirect';
import './App.css';

function App() {
  const BG_INITIAL = '#FFFFFF';
  const [backgroundColor, setBackgroundColor] = useState(BG_INITIAL);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
  }, [backgroundColor]);

  const changeBackgroundColor = (color = BG_INITIAL) => {
    setBackgroundColor(color);
  }
  
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/:company/:event/visor-riesgo' element={<DashboardRisk backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/:group/visor-riesgo' element={<DashboardRisk backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/:group/visor' element={<Dashboard backgroundColor={changeBackgroundColor} show='false' />} />
        <Route exact path='/:company/:event/:group/visor-admin' element={<Dashboard backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/visor' element={<Dashboard backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/visor' element={<DashboardRedirect backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/:group/registro' element={<Register backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/:group/encuesta' element={<Quiz backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/encuesta' element={<QuizRedirect backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/encuesta' element={<QuizRedirect backgroundColor={changeBackgroundColor} />} />
        <Route exact path='/:company/:event/admin-grupos' element={<GroupManagement backgroundColor={changeBackgroundColor} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
