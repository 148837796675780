/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useLocalStorage from '../../services/useLocalStorage';
import axios from '../../services/useAxios';
import useQuizData from '../../services/useQuizData';
import HeatMap from '../../components/HeatMap';
import './styles.css';

function DashboardRisk() {
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [risks, setRisks] = useState([]);
  const [managements, setManagements] = useState([]);
  const { setLocalStorage, getLocalStorage } = useLocalStorage();
  const { quizData } = useQuizData();

  useEffect(() => {
    getFilterManagement();
    getDataGraph();

    const intervalId = setInterval(getDataGraph, 10000);

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  const getDataGraph = async () => {
    const quizDataBody = await quizData();

    const dataBody = {
      ...quizDataBody
    }

    const idManagementLocal = getLocalStorage('idManagement');

    dataBody.idManagement = idManagementLocal;

    try {
      const response = await axios.post('/dashboard/riskGraph', dataBody)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setTotalUsers(data.totalUsers);
      setRisks(data.riskQuiz);
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  const getFilterManagement = async () => {
    try {
      const response = await axios.get('/dashboard/getManagementList')
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setManagements(data.management);
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:52 ~ getFilterManagement ~ err:', err);
    }
  }

  const handleChangeFilterManagement = async (event) => {
    const selectedValue = event.target.value;
    setLocalStorage('idManagement', selectedValue);
    getDataGraph();
  }

  return (
    <main className="container m-auto">
      <div className="w-full m-auto md:w-9/12">
        {
          loading ?
            <div className="flex h-screen justify-center items-center">
              <h1>Cargando</h1>
            </div>
          : <div className="flex justify-center items-center flex-col px-5 py-5">
              <div className="w-full py-5">
                <div className="flex justify-between items-center">
                  <h3 className="text-primary">
                    <span className="font-bold uppercase text-md">General </span>
                    <span className="text-sm">/ {totalUsers} participante{totalUsers === 1 ? '' : 's'}</span>
                  </h3>
                  <div>
                    <div className="flex items-center justify-between w-full">
                      <label htmlFor="filterManagement" className="mr-2">Gerencia:</label>
                      <div className="relative inline-block w-64">
                        <select
                          id="filterManagement"
                          name="filterManagement"
                          className="block appearance-none w-full bg-fogGray text-gray rounded-md px-4 py-2 pr-8 focus:outline-none focus:border-blue-500"
                          onChange={handleChangeFilterManagement}
                        >
                          <option value=''>Todas</option>
                          {managements.map((management) => (
                            <option key={management.id} value={management.id}>
                              {management.label}
                            </option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray">
                          <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:mb-4 md:grid md:grid-cols-2 md:gap-4">
                {
                  risks.map((risk) => (
                    <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full" key={risk.idRiskQuiz}>
                      <HeatMap data={risk.riskQuizItems} title={risk.riskQuizLabel} />
                    </div>
                  ))
                }
              </div>
            </div>
        }
      </div>
    </main>
  )
}

export default DashboardRisk;