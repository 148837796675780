import { useEffect, useState } from 'react'

const arrayClass = ['top-0 left-0', 'top-0 right-0', 'bottom-0 left-0', 'bottom-0 right-0'];

function RiskAnswer(props) {
  const [answer, setAnswer] = useState({});
  const [questionId, setQuestionId] = useState(0);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setAnswer(props.answer);
    setQuestionId(props.questionId);
    setPosition(props.position);
  }, [props.answer, props.position, props.questionId])

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    props.handle(+selectedValue);
  };

  return (
    <div className={`absolute w-1/2 h-1/2 p-2 ${arrayClass[position]}`}>
      <input
        type="radio"
        id={`quiz-${answer.answerId}`}
        name={`quiz-${questionId}`}
        value={answer.answerId}
        className="hidden peer"
        onChange={handleRadioChange}
      />
      <label htmlFor={`quiz-${answer.answerId}`} className="inline-flex items-center justify-between w-full h-full p-3 text-gray bg-white border-2 border-fogGray rounded-2xl cursor-pointer peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary text-center">                           
        <div className="block">
          <div className="w-full text-base">{answer.description}</div>
        </div>
      </label>
    </div>
  )
}

export default RiskAnswer