import axios from "axios";

const PATH = process.env.REACT_APP_API || 'https://quiz-app-server-production-4a31.up.railway.app';

const instance = axios.create({
  baseURL: `${PATH}/api`,
  timeout: 5000,
  headers: {
    "Content-type": "application/json"
  }
});

export default instance;
