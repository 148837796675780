/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from '../../services/useAxios';
import useQuizData from '../../services/useQuizData';
import GroupActions from "../../components/GroupActions"

function GroupManagement() {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const { quizData } = useQuizData();

  useEffect(() => {
    getGroups()
  }, [])

  const handlePlay = async (idEventGroup) => {
    const objData = {
      idEventGroup
    }

    try {
      const response = await axios.post('/dashboard/groups/start', objData)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      console.log('🚀 ~ file: index.jsx:26 ~ handlePlay ~ data:', data);
      getGroups()
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  const handleStop = async (idEventGroup) => {
    const objData = {
      idEventGroup
    }

    try {
      const response = await axios.post('/dashboard/groups/stop', objData)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      console.log('🚀 ~ file: index.jsx:26 ~ handlePlay ~ data:', data);
      getGroups()
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  const getGroups = async () => {
    const quizDataBody = await quizData();

    const objData = {
      idCorporateEvent: quizDataBody.event
    }
    try {
      const response = await axios.post('/dashboard/groups', objData)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      const { eventGroups } = data;
      setGroups(eventGroups)
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  return (
    <div className="flex justify-center items-center flex-col px-5 pb-5">
      <div className="w-full md:w-1/2 bg-white bg-opacity-50">
        <div className="flex justify-between border-b-2 border-primary border-dashed py-5 border-opacity-30">
          <div className="text-left">
            <h1 className="text-primary text-xl"><strong className='font-bold text-xl md:text-2xl'>Grupos</strong></h1>
          </div>
        </div>
        <div className="containerTable w-full pt-5">
          {
            !loading ? (
              <table className="table-auto w-full">
                <thead>
                  <tr className="text-left text-xs text-white bg-primary">
                    <th className="p-1">Grupo</th>
                    <th className="p-1">Inicio</th>
                    <th className="p-1">Fin</th>
                    <th className="p-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    groups.map((group) => (    
                      <tr key={group.idEventGroup} className='border-t border-gray text-gray border-opacity-20'>
                        <td className="px-2 py-4 text-md uppercase font-bold">
                          <p>{group.label}</p>
                        </td>
                        <td className="px-2 py-4 text-sm">
                          <p>{group.from}</p>
                        </td>
                        <td className="px-2 py-4 text-sm">
                          <p>{group.to}</p>
                        </td>
                        <td className="px-2 py-4 text-sm">
                          <GroupActions handlePlay={handlePlay} handleStop={handleStop} eventGroup={group} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center items-center">
                <h1>Cargando...</h1>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default GroupManagement