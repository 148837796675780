/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from '../../services/useAxios';
import useQuizData from '../../services/useQuizData';
import useLocalStorage from '../../services/useLocalStorage';
import Success from '../../components/Success';

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    managementId: yup.string().required(),
  })
  .required();

function Register(props) {
  const navigate = useNavigate();
  const [ disabledBtn, setDisableBtn] = useState(false);
  const [ registerStatus, setRegisterStatus ] = useState(false);
  const [ managements, setManagements ] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [successTitle, setSuccessTitle] = useState('');
  const { quizData } = useQuizData();
  const { setLocalStorage, getLocalStorage } = useLocalStorage();

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    props.backgroundColor('#ff6900');
    const client = getLocalStorage('client');

    // loadManagement();
    loadSuppliers();

    if (client) {
      setRegisterStatus(true);
      redirectUrl();
    }

    return () => {
      props.backgroundColor()
    }
  }, []);

  const loadSuppliers = async () => {
    try {
      const response = await axios.get('/business/supplierList')
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setManagements(data.suppliers);
    } catch (err) {
      const { data } = err.response;
      const { message } = data;
      console.log('🚀 ~ file: index.jsx:56 ~ loadManagement ~ message:', message);
    }
  };

  // const loadManagement = async () => {
  //   try {
  //     const response = await axios.get('/business/managementList', { params: { company: 'cerroverde', typeManagement: 2 } })
  //     const { data: dataResponse } = response;
  //     const { data } = dataResponse;
  //     setManagements(data.management);
  //   } catch (err) {
  //     const { data } = err.response;
  //     const { message } = data;
  //     console.log('🚀 ~ file: index.jsx:56 ~ loadManagement ~ message:', message);
  //   }
  // };
  
  const onSubmit = async (data) => {
    if (disabledBtn) {
      return;
    }

    setDisableBtn(true);

    const quizDataBody = await quizData();

    const dataBody = {
      ...data,
      ...quizDataBody
    }

    try {
      const response = await axios.post('/interviewed/register', dataBody)
      const { data: dataResponse } = response;
      const { data, message } = dataResponse;
      const { id } = data;
      const bodyData = {...dataBody, id, messageResponse: message}
      setLocalStorage('client', bodyData);
      setRegisterStatus(true);

      const { messageResponse } = bodyData;
      setSuccessMessage(messageResponse);
      // const [name] = firstNameALl.split(' ');
      setSuccessTitle('');

      setTimeout(() => {
        redirectUrl();
      }, 3000);
      
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:35 ~ onSubmit ~ err:', err);
    }
  }

  const redirectUrl = async () => {
    const quizDataBody = await quizData();
    navigate(`/${quizDataBody.businessName}/${quizDataBody.event}/${quizDataBody.group}/encuesta`)
  }

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="container flex justify-center p-5 flex-col items-center">
        <div className="text-center mb-5 py-5 w-6/12">
          <h1 className="text-white text-4xl md:text-5xl"><span className="font-bold">REGISTRO</span></h1>
        </div>
        <div className="shadow-lg bg-white rounded-xl w-full md:w-1/2 px-5 pt-6 pb-8">
          {
            registerStatus ? (
              <Success message={successMessage} title={successTitle} caption="Espera un momento que estamos cargando las preguntas." />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                {/*
                  * Ini form hidden
                */}
                <div className="w-full mb-6 hidden">
                  <label
                    htmlFor="firstName"
                    className={`block uppercase tracking-wide text-md font-bold mb-2 ${errors?.firstName ? "text-red" : "text-black"}`}>
                    Nombre y apellido
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Nombre y apellido"
                    className={`appearance-none block w-full border  rounded py-4 px-4 leading-tight focus:outline-none ${errors?.firstName ? "border-red" : "border-gray"}`}
                    value='Nombre'
                    { ...register('firstName') }
                  />
                  {errors?.firstName && (
                    <p className="text-red text-sm mt-2">
                      El campo nombre es requerido.
                    </p>
                  )}
                </div>
                <div className="w-full mb-6 hidden">
                  <label
                    htmlFor="email"
                    className={`block uppercase tracking-wide text-md font-bold mb-2 ${errors?.email ? "text-red" : "text-black"}`}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="encuesta@prueba.com"
                    className={`appearance-none block w-full border rounded py-4 px-4 leading-tight focus:outline-none ${errors?.email ? "border-red" : "border-gray"}`}
                    value='email@prueba.com'
                    { ...register('email') }
                  />
                  {errors?.email && (
                    <p className="text-red text-sm mt-2">
                      El campo email es requerido.
                    </p>
                  )}
                </div>
                {/*
                  * End form hidden
                */}
                <div className="w-full mb-6">
                  <label
                    htmlFor="managementList"
                    className={`block uppercase tracking-wide text-md font-bold mb-2 ${errors?.managementId ? "text-red" : "text-black"}`}
                  >
                    Nombre de empresa
                  </label>
                  <select
                    name="managementId"
                    id="managementId"
                    className={`appearance-none block w-full border rounded py-4 px-4 leading-tight focus:outline-none ${errors?.managementId ? "border-red" : "border-gray"}`}
                    {...register('managementId')}
                  >
                    <option value="">Seleccione...</option>
                    {managements.map((management) => (
                      <option key={management.id} value={management.id}>
                        {management.label}
                      </option>
                    ))}
                    {/* {
                      managements.map((management) => (
                        <optgroup key={management.id} label={management.label}>
                          {
                            management.values.map((management2) => (
                              <>
                                <option key={management2.id} disabled={management2.values.length} value={management2.label} dangerouslySetInnerHTML={{__html: management2.label}}>
                                </option>
                                {
                                  management2.values.map((management3) => (
                                    <option key={management3.id} disabled={management3.length} value={management3.id} dangerouslySetInnerHTML={{__html: management3.label}}>
                                    </option>
                                  ))
                                }
                              </>
                            ))
                          }
                        </optgroup>
                      ))
                    } */}
                  </select>
                  {errors?.managementId && (
                    <p className="text-red text-sm mt-2">
                      Por favor, seleccione una opción.
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <h5 className="text-gray pr-5 text-sm">Todos los datos son obligatorios.</h5>
                  <button className="bg-primary hover:bg-secondary text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Registrar
                  </button>
                </div>
              </form>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Register