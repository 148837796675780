/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useQuizData from '../../services/useQuizData';
import useLocalStorage from '../../services/useLocalStorage';
import ImageSuccess from '../../images/png/image_success.png';

function Success(props) {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [caption, setCaption] = useState('');
  const { getLocalStorage, removeLocalStorage } = useLocalStorage();
  const navigate = useNavigate();
  const { quizData } = useQuizData();

  useEffect(() => {
    validUrl();
    setMessage(props.message);
    setTitle(props.title);
    setCaption(props.caption);
  }, [])

  const validUrl = async () => {
    const quizDataBody = await quizData();
    const businessNameQS = quizDataBody.businessName;
    const eventQS = +quizDataBody.event;
    const groupQS = +quizDataBody.group;

    const clientLocalStorage = getLocalStorage('client');
    const businessNameLS = clientLocalStorage?.businessName;
    const eventLS = +clientLocalStorage?.event;
    const groupLS = +clientLocalStorage?.group;

    const urlRedirect = `/${businessNameQS}/${eventQS}/${groupQS}/registro`;

    if ((businessNameQS !== businessNameLS) || (eventQS !== eventLS) || (groupQS !== groupLS)) {
      removeLocalStorage('client');
      removeLocalStorage('quiz');
      removeLocalStorage('questions');
      return navigate(urlRedirect);
    }
  }

  const handleClean = async () => {
    const quizDataBody = await quizData();
    const businessNameQS = quizDataBody.businessName;
    const eventQS = +quizDataBody.event;
    const groupQS = +quizDataBody.group;
    const urlRedirect = `/${businessNameQS}/${eventQS}/${groupQS}/registro`;
    removeLocalStorage('questions');
    removeLocalStorage('quiz');
    removeLocalStorage('client');
    return navigate(urlRedirect);
  }

  return (
    <div className="flex flex-col items-center gap-12">
      <div className="text-center w-2/3">
        <h1 className="text-black text-1xl font-bold uppercase">{message}</h1>
        <h1 className="text-primary text-4xl font-bold uppercase">{title}</h1>
      </div>
      <div className="w-64">
        <img src={ImageSuccess} className="w-full" alt="Success" />
      </div>
      <div className="text-center w-full">
        <h4 className="text-gray text-sm">{caption}</h4>
      </div>
      {
        title === '¡Registro exitoso!' && (
          <div className="text-center">
            <button className="bg-primary hover:bg-secondary text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleClean}>Nueva encuesta</button>
          </div>
        )
      }
    </div>
  )
}

export default Success