/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useQuizData from '../../services/useQuizData';
import axios from '../../services/useAxios';
import Question from "../../components/Question";
import Success from '../../components/Success';
import useLocalStorage from '../../services/useLocalStorage';

function Quiz() {
  const navigate = useNavigate();
  const { quizData } = useQuizData();
  const [loading, setLoading] = useState(true);
  const [questionsAll, setQuestions] = useState({});
  const [questionIdx, setQuestionIdx] = useState(0);
  const [question, setQuestion] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [answerQuestion, setAnswerQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [successTitle, setSuccessTitle] = useState('');
  const { setLocalStorage, getLocalStorage } = useLocalStorage();

  useEffect(() => {
    const client = getLocalStorage('client');
    const questions = getLocalStorage('questions');

    if(!client) {
      validUrl();
    }

    if (questions) {
      setAnswers(questions.questions);
      setQuestionIdx(questions.questions.length);
    }

    const quiz = getLocalStorage('quiz');

    setSuccessMessage('');
    setSuccessTitle('¡Registro exitoso!');

    if (quiz?.status === 2) {
      setIsEnd(true);
    }

    if (!quiz && client) {
      registerStartQuestion(client)
    }

    getQuestions();

  }, [])

  useEffect(() => {
    if (!loading) {
      const getQuestion = questionsAll?.questions[questionIdx];
      setQuestion(getQuestion);
    }
  }, [questionIdx, loading])

  const validUrl = async () => {
    const quizDataBody = await quizData();
    return navigate(`/${quizDataBody.businessName}/${quizDataBody.event}/${quizDataBody.group}/registro`)
  }

  const registerStartQuestion = async (client) => {

    const dataBody = {
      idTeamMember: client.id,
      status: 1
    }

    try {
      const response = await axios.post('/questions/record', dataBody)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setLocalStorage('quiz', data);
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:72 ~ handleNext ~ err:', err);
    }
  }

  const getQuestions = async () => {
    const quizDataBody = await quizData();
    const { event: eventId } = quizDataBody;

    const dataBody = {
      eventId,
    }

    try {
      const response = await axios.post('/questions', dataBody)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setQuestions(data)
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  const handleNext = async () => {
    const numberQuestion = questionIdx + 1;
    const addQuestions = [...answers, answerQuestion];

    if (numberQuestion <= questionsAll.questions.length) {
      setAnswers(addQuestions)
      setLocalStorage('questions', {questions: addQuestions})
    }

    if (numberQuestion < questionsAll.questions.length) {
      setQuestionIdx(numberQuestion)
      setBtnDisabled(true);
    } else {
      const quiz = getLocalStorage('quiz');

      const dataBody = {
        idQuizRecord: quiz.id_quiz_record,
        answers: addQuestions
      }

      try {
        await axios.post('/questions/register', dataBody)
        setLocalStorage('quiz', {...quiz, status: 2});
        setIsEnd(true);
      } catch (err) {
        console.log('🚀 ~ file: index.jsx:72 ~ handleNext ~ err:', err);
      }
    }
  }

  const handleAnswerQuestion = (data) => {
    setAnswerQuestion(data);
    if (data) {
      setBtnDisabled(false);
    }
  }

  return (
    <div className="flex justify-center items-center flex-col px-5 pb-5">
      <div className="w-full md:w-1/2 bg-white bg-opacity-50">
        {
          loading ?
            <div className="flex h-screen justify-center items-center">
              <h1>Cargando</h1>
            </div>
          : !isEnd ?
            <>
              <div className="flex justify-between border-b-2 border-primary border-dashed py-5 border-opacity-30">
                <div className="text-left">
                  <h1 className="text-primary text-xl"><strong className='font-bold text-xl md:text-2xl'>Pregunta {questionIdx + 1}</strong>/{ questionsAll.questions.length }</h1>
                </div>
              </div>
              <div className="containerQuiz w-full">
                {
                  question && (
                    <Question
                      question={question}
                      handle={handleAnswerQuestion}
                    />
                  )
                }
              </div>
              <div className="footerQuiz fixed bg-white w-full bg-opacity-90 left-0 bottom-0 px-4 py-3 shadow-inner flex justify-center">
                <div className="w-full md:w-1/2 flex justify-end">
                  <button
                    type="button"
                    className={` text-white px-9 py-3 uppercase font-bold rounded-lg ${btnDisabled ? 'bg-gray' : 'bg-black'}`}
                    disabled={btnDisabled}
                    onClick={handleNext}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </>
            : <div className="flex justify-center h-screen items-center">
              <Success message={successMessage} title={successTitle} caption="Muchas gracias por tu participación." />
            </div>
        }
      </div>
    </div>
  )
}

export default Quiz