const useLocalStorage = () => {
  const setLocalStorage = (key, data) => {
    const body = JSON.stringify(data);
    localStorage.setItem(key, body);
    return true;
  }

  const getLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  const removeLocalStorage = (key) => {
    return localStorage.removeItem(key);
  }

  return {
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage
  }
};

export default useLocalStorage;
