import { Link } from 'react-router-dom';
import './styles.css';

function Home() {
  return (
    <div className="flex h-screen justify-center items-center flex-col">
      <div className="container flex justify-center px-5">
        <div className="shadow-lg bg-white rounded w-full md:w-1/2 text-center decor-brand relative pt-12">
          <div className="pt-5 pb-14 px-5">
            <h1 className="font-bold text-4xl md:text-5xl mb-10">Autoevaluación</h1>
            <ul className="grid grid-cols-1 gap-3">
              <li className='flex justify-center'>
                <Link to={'cerroverde/1/admin-grupos'} className='bg-primary hover:bg-secondary text-white font-bold py-4 px-6 rounded-md'>
                  Demo: Habilitar grupo
                </Link>
              </li>
              <li className='flex justify-center'>
                <Link to={'cerroverde/1/1/encuesta'} className='bg-primary hover:bg-secondary text-white font-bold py-4 px-6 rounded-md'>
                  Demo: Iniciar encuesta
                </Link>
              </li>
              <li className='flex justify-center'>
                <Link to={'cerroverde/1/1/visor'} className='bg-primary hover:bg-secondary text-white font-bold py-4 px-6 rounded-md'>
                  Demo: Dashboard encuesta
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home