/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../services/useAxios';

function DashboardRedirect() {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    getDashboardUrl();
  }, []);

  const getDashboardUrl = async () => {
    try {
      const response = await axios.get('/getDashboardUrl', { params: { company: 'cerroverde' } })
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      return navigate(data.url);
    } catch (err) {
      const { data } = err.response;
      const { message } = data;
      setMessage(message);
    }
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center w-2/3">
        <h1 className="text-2xl">{message || 'Cargando...'}</h1>
      </div>
    </div>
  )
}

export default DashboardRedirect