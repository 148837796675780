import { useRef, useState, useEffect } from 'react';
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import './styles.css'

function HeatMap(props) {
  const sectionRef = useRef(null);
  const [showGraph, setShowGraph] = useState(false);
  const [zones, setZones] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setZones(props.data);
    if (props.title) setTitle(props.title);
  }, [props]);

  const handleCapture = () => {
    const sectionToCapture = sectionRef.current;
    html2canvas(sectionToCapture, {
      scale: 2,
      useCORS: true // Enable CORS to allow screenshot of external images
    }).then(canvas => {
      canvas.toBlob(blob => saveAs(blob, "download.png"));
    });
  };

  const handleShowGraph = () => {
    setShowGraph(!showGraph);
  }

  return (
    <>
      {
        title && (
          <div className='p-5'>
            <h1 className="font-bold text-gray">{title}</h1>
          </div>
        )
      }
      <div className='flex p-5 w-full justify-between gap-5'>
        <div>
          {
            !title && (
              <div className="mb-5">
                <h1 className="font-bold text-gray">Mapa de calor</h1>
              </div>
            )
          }
          <div className='grid grid-cols-1 gap-5'>
            <button className="bg-gray text-white font-medium px-5 py-1 rounded-lg" onClick={handleShowGraph}>Visualizar</button>
            {
              showGraph && (
                <button className="bg-primary text-white font-medium px-5 py-1 rounded-lg" onClick={handleCapture}>Descargar</button>
              )
            }
          </div>
        </div>
        <div className='' ref={sectionRef} id="heatMap">
          {
            showGraph && (
              <div>
                <div className="flex items-center justify-center rounded-lg overflow-hidden">
                  <div className="relative w-64 h-64 bg-gray-500">
                    {
                      zones && (
                        zones.map((zone) => (
                          <div key={zone.id} className={`absolute w-1/2 h-1/2 ${[1, 2].includes(+zone.id) ? 'top-0' : 'bottom-0'} ${[1, 3].includes(+zone.id) ? 'left-0' : 'right-0'}`} style={{backgroundColor: zone.background}}>
                            <div className='flex h-full items-center justify-center'>
                              <div className="w-3/4 text-center" style={{color: zone.color}}>
                                <h1 className='font-bold text-2xl'>{zone.percentage}%</h1>
                                <h4 className="font-bold text-sm">Zona de <br />{zone.label}</h4>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    }
                    <div className="absolute arrow arrow-y bg-gray/10 top-2/4 left-2/4"></div>
                    <div className="absolute arrow arrow-x bg-gray/10 top-2/4 left-2/4"></div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default HeatMap