import { useParams } from 'react-router-dom';


const useQuizData = () => {
  const { company, event, group } = useParams();

  const quizData = async () => ({
    businessName: company,
    event: event,
    group: group
  })

  return { quizData }
};

export default useQuizData;
