/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useLocalStorage from '../../services/useLocalStorage';
import axios from '../../services/useAxios';
import useQuizData from '../../services/useQuizData';
// import BarChart from '../../components/BarChart';
// import HeatMap from '../../components/HeatMap';
import ImageGears from '../../images/png/gears_cicle.png';
import ImageGearsHidden from '../../images/png/gears_cicle_hidden.png';
import './styles.css';

function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [showGraph, setShowGraph] = useState(false);
  // const [totalAverage, setTotalAverage] = useState({});
  // const [behaviors, setBehaviors] = useState([]);
  // const [heatMap, setHeatMap] = useState([]);
  const [behaviorsPercentage, setBehaviorsPercentage] = useState([]);
  const [managements, setManagements] = useState([]);
  const [showVisor, setShowVisor] = useState(true);
  const { setLocalStorage, getLocalStorage } = useLocalStorage();
  const { quizData } = useQuizData();

  useEffect(() => {
    getFilterManagement();
    getDataGraph();

    const intervalId = setInterval(getDataGraph, 10000);

    if (props.show && props.show === 'false') {
      setShowVisor(false)
    }

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  const getDataGraph = async () => {
    const quizDataBody = await quizData();

    const dataBody = {
      ...quizDataBody
    }

    const idManagementLocal = getLocalStorage('idManagement');

    dataBody.idManagement = idManagementLocal;

    try {
      const response = await axios.post('/dashboard/barGraph', dataBody)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setTotalUsers(data.totalUsers);
      // setTotalAverage(data.totalAverage);
      // setBehaviors(data.behaviors);
      // setHeatMap(data.heatmap);
      setBehaviorsPercentage(data.behaviorsPercentage);
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  const getFilterManagement = async () => {
    try {
      const response = await axios.get('/dashboard/getManagementList')
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      setManagements(data.management);
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:52 ~ getFilterManagement ~ err:', err);
    }
  }

  const handleChangeFilterManagement = async (event) => {
    const selectedValue = event.target.value;
    // setIdManagement(selectedValue);
    setLocalStorage('idManagement', selectedValue);
    getDataGraph();
  }

  const handleShowGraph = () => {
    setShowGraph(!showGraph);
  }

  return (
    <main className="container m-auto">
      <div className="w-full m-auto md:w-9/12">
        {
          loading ?
            <div className="flex h-screen justify-center items-center">
              <h1>Cargando</h1>
            </div>
          : <div className="flex justify-center items-center flex-col px-5 py-5">
              {
                showVisor && (
                  <div className="w-full py-5">
                    <div className="flex justify-between items-center">
                      <h3 className="text-primary">
                        <span className="font-bold uppercase text-md">General </span>
                        <span className="text-sm">/ {totalUsers} participante{totalUsers === 1 ? '' : 's'}</span>
                      </h3>
                      <div>
                        <div className="flex items-center justify-between w-full">
                          <label htmlFor="filterManagement" className="mr-2">Gerencia:</label>
                          <div className="relative inline-block w-64">
                            <select
                              id="filterManagement"
                              name="filterManagement"
                              className="block appearance-none w-full bg-fogGray text-gray rounded-md px-4 py-2 pr-8 focus:outline-none focus:border-blue-500"
                              onChange={handleChangeFilterManagement}
                            >
                              <option value=''>Todas</option>
                              {managements.map((management) => (
                                <option key={management.id} value={management.id}>
                                  {management.label}
                                </option>
                              ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray">
                              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="w-full md:mb-4 md:grid md:grid-cols-1 md:gap-4">
                <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full p-5">
                  <div className='flex p-5 w-full items-center justify-between gap-5'>
                    <div>
                      <h3 className="font-bold text-gray">Promedio general</h3>
                    </div>
                    <div className='grid grid-cols-1 gap-5'>
                      <button className="bg-gray text-white font-medium px-5 py-1 rounded-lg" onClick={handleShowGraph}>Visualizar</button>                      
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <div className='md:w-8/12 relative'>
                      <figure>
                        <img src={ImageGears} alt="Gráfica" />
                      </figure>
                      <figure className={`absolute top-0 left-0 ${!showGraph ? 'hidden' : '' }`}>
                        <img src={ImageGearsHidden} alt="Gráfica Hidden" />
                      </figure>
                      {
                        showGraph && (
                          <div className="absolute md:w-1 md:h-1 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <div className="relative h-full">
                              {
                                behaviorsPercentage.map((behavior) => (
                                  <div key={behavior.caption} className={`absolute rounded-full 2xl:w-[160px] 2xl:h-[160px] xl:w-[140px] xl:h-[140px] lg:w-[110px] lg:h-[110px] md:w-[80px] md:h-[80px] w-[18vw] h-[18vw] ${behavior.className}`}>
                                    <div className="text-center w-full h-full flex flex-col items-center justify-center">
                                      <h2 className='2xl:text-6xl xl:text-5xl lg:text-4xl md:text-2xl font-medium text-3xl'>{behavior.percentage}<span className='text-xl'>%</span></h2>
                                      <h5 className='leading-3 2xl:text-md xl:text-sm lg:text-[12px] md:text-[8px] hidden md:block'>{behavior.caption}</h5>
                                    </div>
                                  </div>
                                ))
                              }
                              <div className="hidden text-white md:bg-[#ff9900] 2xl:left-[-70px] 2xl:top-[-310px] xl:w-[90px] xl:h-[90px] xl:left-[-62px] xl:top-[-258px] lg:left-[-48px] lg:top-[-201px] md:left-[-34px] md:top-[-144px] top-[-36svw] left-[-8.25svw]"></div>
                              <div className="hidden md:bg-[#bfbfc0] 2xl:left-[166px] 2xl:top-[-134px] xl:left-[133px] xl:top-[-114px] lg:left-[105px] lg:top-[-89px] md:left-[75px] md:top-[-64px] top-[-16svw] left-[20svw]"></div>
                              <div className="hidden md:bg-[#ba8748] 2xl:left-[82px] 2xl:bottom-[-298px] xl:left-[64px] xl:bottom-[-250px] lg:left-[50px] lg:bottom-[-196px] md:left-[36px] md:bottom-[-140px] bottom-[-35svw] left-[10svw]"></div>
                              <div className="hidden md:bg-[#007cb7] 2xl:left-[-218px] 2xl:bottom-[-308px] xl:left-[-185px] xl:bottom-[-257px] lg:left-[-144px] lg:bottom-[-201px] md:left-[-104px] md:bottom-[-145px] bottom-[-35svw] left-[-25svw]"></div>
                              <div className="hidden md:bg-[#669900] 2xl:left-[-322px] 2xl:top-[-134px] xl:left-[-269px] xl:top-[-114px] lg:left-[-209px] lg:top-[-89px] md:left-[-150px] md:top-[-64px] top-[-16svw] left-[-38svw]"></div>
                              <div className="hidden bg-transparent 2xl:left-[-62px] 2xl:top-[-40px] xl:left-[-38px] xl:top-[-36px] lg:left-[-48px] lg:top-[-36px] md:left-[-34px] md:top-[-24px] top-[-6svw] left-[-8.25svw]"></div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="w-full md:mb-4 md:grid md:grid-cols-2 md:gap-4">
                <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full p-5">
                  <div className="mb-10">
                    <h3 className="font-bold text-gray">Promedio general</h3>
                  </div>
                  <BarChart data={totalAverage} />
                </div>
                <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full">
                  <HeatMap data={heatMap} />
                </div>
              </div>
              {
                behaviors.length > 0 && (
                  <div className="w-full p-5">
                    <h3 className="font-bold text-primary uppercase">Comportamientos observables</h3>
                  </div>
                )
              }
              <div className="w-full md:mb-4 md:grid md:grid-cols-2 md:gap-4">
                {
                  behaviors.map((behavior, idx) => (
                    <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full p-5 h-80 flex flex-col justify-between" key={`behavior-${idx + 1}`}>
                      <div className="mb-5">
                        <h3 className="font-bold text-gray">{behavior.title}</h3>
                      </div>
                      <BarChart data={behavior} />
                    </div>
                  ))
                }
              </div> */}
            </div>
        }
      </div>
    </main>
  )
}

export default Dashboard;